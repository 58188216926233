import {
  CreditCardIcon,
  HomeIcon,
  ThumbDownIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { SidebarNav, SidebarTab } from "theme";

export function AppSidebar() {
  return (
    <SidebarNav>
      <SidebarTab icon={HomeIcon} href="/dashboard">
        Home
      </SidebarTab>
      <SidebarTab icon={UserCircleIcon} href="/account">
        Account
      </SidebarTab>
      <SidebarTab icon={CreditCardIcon} href="/subscription">
        Subscription
      </SidebarTab>
      <SidebarTab
        icon={ThumbDownIcon}
        href="https://tally.so/r/3NpDBj"
        target="_blank"
      >
        Feedback
      </SidebarTab>
    </SidebarNav>
  );
}
