import { NavBar } from "components/NavBar/NavBar";
import { ComponentType, HTMLAttributeAnchorTarget, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { cn } from "util/cn";

type Props = {
  children?: ReactNode;
  sidebar: ReactNode;
};
export function MasterDetail({ sidebar, children }: Props) {
  return (
    <div className="min-h-full">
      <NavBar />
      <div className="py-4 sm:py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
            <nav
              aria-label="Sidebar"
              className="sticky top-4 divide-y divide-gray-300"
            >
              {sidebar}
            </nav>
          </div>
          <main className="lg:col-span-9 xl:col-span-9">{children}</main>
        </div>
      </div>
    </div>
  );
}

export function SidebarNav({ children }: { children?: ReactNode }) {
  return <main className="lg:col-span-9 xl:col-span-9">{children}</main>;
}

type SidebarTabProps = {
  icon: ComponentType<any>;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  children?: ReactNode;
};
export function SidebarTab({
  children,
  icon: Icon,
  href,
  target,
}: SidebarTabProps) {
  if (target == "_blank") {
    return (
      <a href={href} target={target} className={linkClassName(false)}>
        <Icon className={iconClassName(false)} aria-hidden="true" />
        <span className="truncate">{children}</span>
      </a>
    );
  }
  return (
    <NavLink
      to={target == "_blank" ? { pathname: href } : href}
      target={target}
      className={({ isActive }) => linkClassName(isActive)}
    >
      {({ isActive }) => {
        return (
          <>
            <Icon className={iconClassName(isActive)} aria-hidden="true" />
            <span className="truncate">{children}</span>
          </>
        );
      }}
    </NavLink>
  );

  function iconClassName(isActive: boolean) {
    return cn(
      "flex-shrink-0 -ml-1 mr-3 h-6 w-6",
      isActive ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500"
    );
  }

  function linkClassName(isActive: boolean): string | undefined {
    return cn(
      "cursor-pointer group flex items-center px-3 py-2 text-sm font-medium rounded-md",
      isActive ? "bg-gray-200 text-gray-900" : "text-gray-600 hover:bg-gray-50"
    );
  }
}
