import React, { useEffect, useRef } from "react";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const useDidMountEffect = (func: any, dependencies: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, dependencies);
};
