import { DuplicateIcon, KeyIcon } from "@heroicons/react/outline";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

export type Props = {
  label?: string;
  value?: string;
};
export function CopyTokenText({ label, value }: Props) {
  const [copied, setCopied] = useState(false);

  const loadingValue = value === undefined;

  function onClickCopy() {
    if (!value) return;

    navigator.clipboard.writeText(value);
    setCopied(true);
  }

  return (
    <div>
      {label ? (
        <label
          htmlFor="accessToken"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      ) : null}

      <div className="bg-white mt-1 relative rounded-md shadow-sm border-2 flex items-stretch flex-grow">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="key"
          name="accessToken"
          id="accessToken"
          value={loadingValue ? "Loading token..." : value}
          disabled={true}
          className="p-2 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300 cursor-copy"
        />
        <button
          type="button"
          disabled={loadingValue}
          onClick={onClickCopy}
          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-md bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-50"
        >
          <DuplicateIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      <Snackbar
        open={copied}
        onClose={() => setCopied(false)}
        autoHideDuration={3000}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
}
