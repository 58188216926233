import { ReactNode } from "react";
import { cn } from "util/cn";

type InstructionStepProps = {
  name: ReactNode;
  step: number;
  children: ReactNode;
};

export function InstructionStep({
  name,
  step,
  children,
}: InstructionStepProps) {
  return (
    <li>
      <a className="relative flex items-start group" aria-current="step">
        <span className="min-w-0 flex flex-col">
          <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
            {step}. {name}
          </span>
          <span className="text-sm text-gray-500">{children}</span>
        </span>
      </a>
    </li>
  );
}

export function InstructionSteps({ children }: { children: ReactNode }) {
  return (
    <ol role="list" className="flex flex-col space-y-8">
      {children}
    </ol>
  );
}
