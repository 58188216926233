import { Switch } from "@headlessui/react";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import DeleteModal from "../../DeleteModal/DeleteModal";
import { MasterDetail } from "theme";
import { AppSidebar } from "components/AppSidebar";

export default function AccountSettings() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [accountDeleteSuccess, setAccountDeleteSuccess] = useState(false);
  const [accountDeleteError, setAccountDeleteError] = useState(false);
  const [publicSavedContentPref, setPublicSavedContentPref] = useState(true);
  const [subscription, setSubscription] = useState<any>();
  const [userPicture, setUserPicture] = useState<string>("");

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      api.getUserPreferences().then((res) => {
        if (
          res.data &&
          Object.prototype.hasOwnProperty.call(res.data, "publicSavedContent")
        ) {
          setPublicSavedContentPref(res.data.publicSavedContent);
        }
        getCurrentSubscription();
      });

      if (auth.user.photoURL) {
        setUserPicture(auth.user.photoURL);
      }
    }
  }, [auth.loading]);

  const updatePublicSavedContentPref = (newPref: boolean) => {
    api
      .updateUserPreferences({
        publicSavedContent: newPref,
      })
      .then((res) => {
        setPublicSavedContentPref(newPref);
      })
      .catch((err) => {
        console.error("Error updating public saved content preference - ", err);
      });
  };

  const getCurrentSubscription = async () => {
    api
      .getCustomerSubscriptions()
      .then((subscriptions) => {
        if (subscriptions.data.subscriptions.length > 0) {
          for (const subscription of subscriptions.data.subscriptions) {
            if (
              subscription.status === "active" ||
              subscription.status === "trialing"
            ) {
              setSubscription(subscription);
              return;
            }
          }
          setSubscription(undefined);
        } else {
          setSubscription(undefined);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteUser = () => {
    api
      .deleteUser()
      .then(() => {
        setAccountDeleteSuccess(true);
        window.location.reload();
      })
      .catch((error) => {
        setAccountDeleteError(true);
        console.error("Error deleting user - ", error);
      });
  };

  const closeSnackbar = () => {
    setAccountDeleteSuccess(false);
    setAccountDeleteError(false);
  };

  return (
    <MasterDetail sidebar={<AppSidebar />}>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        {/* Plan */}
        <section aria-labelledby="plan-heading">
          <div>
            <div className="shadow sm:rounded-md sm:overflow-hidden mx-2 sm:mx-0">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h2
                    id="plan-heading"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Account details
                  </h2>
                </div>
                <div>
                  {loading ? (
                    <div className="animate-pulse space-y-1">
                      <div className="mb-3">
                        <span className="inline-block h-16 w-16 rounded-full overflow-hidden bg-gray-100"></span>
                      </div>
                      <div className="bg-gray-100 text-gray-100 rounded">
                        <strong>Email: </strong>
                        userEmail@example.com
                      </div>
                      <div className="bg-gray-100 text-gray-100 rounded">
                        <strong>Joined: </strong>
                        {new Date().toDateString()}
                      </div>
                    </div>
                  ) : (
                    <div className="fadeIn">
                      <div className="mb-4">
                        {userPicture ? (
                          <img
                            className="h-16 w-16 rounded-full"
                            onError={() => setUserPicture("")}
                            src={userPicture}
                            alt=""
                          />
                        ) : (
                          <span className="inline-block h-16 w-16 rounded-full overflow-hidden bg-gray-100">
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        )}
                      </div>
                      <div>
                        <strong>Email: </strong>
                        {auth.user.email}
                      </div>
                      <div>
                        <strong>Joined: </strong>
                        {new Date(
                          parseInt(auth.user.metadata.createdAt)
                        ).toDateString()}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="shadow sm:rounded-md sm:overflow-hidden mx-2 my-4 sm:mx-0">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h2
                    id="plan-heading"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Manage Account
                  </h2>
                </div>
                <div>
                  {loading ? (
                    <div className="my-3 animate-pulse space-y-1">
                      <div className="flex items-center justify-between bg-gray-100 text-gray-100 rounded">
                        <span className="flex-grow flex flex-col">
                          <span className="text-sm font-medium ">
                            Delete your account
                          </span>
                          <span className="text-sm">
                            <p>
                              This will permanently delete your account and all
                              associated user data.
                            </p>
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="fadeIn">
                      <div className="my-4">
                        <div className="flex items-center justify-between">
                          <span className="flex-grow flex flex-col">
                            <span className="text-sm font-medium text-gray-900">
                              Delete your account
                            </span>
                            <span className="text-sm text-gray-500">
                              <p>
                                This will permanently delete your account and
                                all associated user data.
                              </p>
                            </span>
                          </span>
                          <button
                            disabled={
                              loading ||
                              (subscription && !subscription.cancel_at)
                            }
                            onClick={() => setOpenDeleteModal(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                          >
                            Delete Account
                          </button>
                        </div>
                      </div>
                      {subscription && !subscription.cancel_at && (
                        <>
                          <hr />
                          <div className="my-4">
                            <p className="text-sm text-red-500">
                              Please cancel your subscription before deleting
                              your account
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <DeleteModal
          action={() => deleteUser()}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={"Are you sure you want to delete your account?"}
          description={
            "Account deletion is permanent. You won't be able to recover your account or data after deletion."
          }
        />
        <Snackbar
          open={accountDeleteSuccess}
          onClose={closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Account successfully deleted
          </Alert>
        </Snackbar>
        <Snackbar
          open={accountDeleteError}
          onClose={closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            An error occurred - please try again or contact support
          </Alert>
        </Snackbar>
      </div>
    </MasterDetail>
  );
}
