import {
  FacebookAuthProvider,
  getRedirectResult,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SignInImg from "../../assets/img/signin-img2.jpg";
import TresselFavicon from "../../assets/img/tressel-favicon.png";
import { useAuth } from "../../utils/auth/auth";
import { FirebaseContext } from "../../utils/firebase";
import api from "../../utils/helpers/api";

export default function SignUp() {
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [signInErrorMessage, setSignInErrorMessage] = useState<string>("");

  const signInWithAuthProvider = (
    authProvider:
      | TwitterAuthProvider
      | GoogleAuthProvider
      | FacebookAuthProvider
  ) => {
    setLoading(true);
    auth.signInWithAuthProvider(authProvider);
  };

  const signUpWithEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (email && password) {
      auth
        .signUpWithEmail(email, password)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          if (err.code === "auth/user-not-found") {
            setSignInErrorMessage("Invalid email or password");
          } else if (err.code === "auth/email-already-in-use") {
            setSignInErrorMessage(
              "Email already in use. Please sign into your existing account."
            );
          } else if (err.code === "auth/weak-password") {
            setSignInErrorMessage(
              "Password too weak. Please use symbols, numbers or capital letters"
            );
          } else {
            setSignInErrorMessage("An unknown error occurred while signing in");
            console.error(err);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getRedirectResult(firebase!.auth).catch((err) => {
      console.error(err);
      if (err.code === "auth/account-exists-with-different-credential") {
        setSignInErrorMessage(
          "An account already exists with the same credentials. Please sign in with your originally created account."
        );
      } else {
        setSignInErrorMessage("An unknown error occurred while signing in");
        console.error(err);
      }
    });
  }, []);

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      api
        .createUserIfDoesntExist()
        .then(() => {
          navigate("/dashboard", { replace: true });
        })
        .catch((error) => {
          console.error("Error while creating user - ", error);
        });
    }
    setLoading(false);
  }, [auth.loading]);

  return (
    <>
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto"
                src={TresselFavicon}
                alt="Tressel Favicon"
              />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Register for Tressel
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{" "}
                <Link to={"/signin"}>
                  <span className="font-medium text-indigo-600 hover:text-indigo-500">
                    sign in with an existing account
                  </span>
                </Link>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div>
                  <p className="text-sm font-medium text-gray-700">
                    Sign in with
                  </p>

                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <button
                        disabled={loading}
                        onClick={() =>
                          signInWithAuthProvider(new FacebookAuthProvider())
                        }
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                      >
                        <span className="sr-only">Sign in with Facebook</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    <div>
                      <button
                        disabled={loading}
                        onClick={() =>
                          signInWithAuthProvider(new TwitterAuthProvider())
                        }
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </button>
                    </div>

                    <div>
                      <button
                        disabled={loading}
                        onClick={() =>
                          signInWithAuthProvider(new GoogleAuthProvider())
                        }
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                      >
                        <span className="sr-only">Sign in with Google</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          className="w-5"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 0C5.372 0 0 5.373 0 12s5.372 12 12 12c6.627 0 12-5.373 12-12S18.627 0 12 0zm.14 19.018c-3.868 0-7-3.14-7-7.018c0-3.878 3.132-7.018 7-7.018c1.89 0 3.47.697 4.682 1.829l-1.974 1.978v-.004c-.735-.702-1.667-1.062-2.708-1.062c-2.31 0-4.187 1.956-4.187 4.273c0 2.315 1.877 4.277 4.187 4.277c2.096 0 3.522-1.202 3.816-2.852H12.14v-2.737h6.585c.088.47.135.96.135 1.474c0 4.01-2.677 6.86-6.72 6.86z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mt-6 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form
                  className="space-y-6"
                  onSubmit={(e) => signUpWithEmail(e)}
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="new-email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                    >
                      Register
                    </button>
                    <div className="block text-sm font-medium text-red-500 mt-4">
                      {signInErrorMessage}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={SignInImg}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
