import { TrashIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { classNames } from "../../utils/helpers/misc";

export default function ChooseSubscription() {
  const [billingType, setBillingType] = useState<"monthly" | "yearly">(
    "monthly"
  );
  const [loading, setLoading] = useState(true);
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const auth = useAuth();
  const [error, setError] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [accountDeleteSuccess, setAccountDeleteSuccess] = useState(false);
  const [accountDeleteError, setAccountDeleteError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      api
        .getCustomerSubscriptions()
        .then((subscriptions) => {
          if (subscriptions.data.subscriptions.length > 0) {
            for (const subscription of subscriptions.data.subscriptions) {
              if (
                subscription.status === "active" ||
                subscription.status === "trialing"
              ) {
                setAlreadySubscribed(true);
                setLoading(false);
                return;
              }
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [auth.loading]);

  const signOut = () => {
    navigate("/signout");
  };

  const deleteUser = () => {
    api
      .deleteUser()
      .then(() => {
        setAccountDeleteSuccess(true);
        window.location.reload();
      })
      .catch((error) => {
        setAccountDeleteError(true);
        console.error("Error deleting user - ", error);
      });
  };

  const tiers = [
    {
      name: "Premium",
      href: "#",
      priceMonthly: 5,
      priceYearly: 4,
      description: "For unlocking your consumption/creation workflow",
      includedFeatures: [
        // "Import any tweets, threads & conversations privately via DMs & bookmarks",
        "Import Reddit posts and comments privately by saving",
        "Import Kindle highlights automatically via Chrome extension",
        "Import Pocket highlights automatically",
        "Import Instapaper highlights automatically",
        "Import Raindrop highlights automatically",
        "Import Hypothes.is annotations automatically",
        "Import HackerNews favourites automatically",
        "Quick capture highlights in seconds via Chrome extension",
        "Daily and weekly highlight emails to resurface content you've saved",
        // "Import tweets, threads & conversations <7 days old via public comments",
        "Sync to Notion, Obsidian & Evernote",
        "Syncs every 2 minutes",
      ],
      onClickMonthly: () =>
        goToStripeCheckout(
          process.env.REACT_APP_STRIPE_MONTHLY_SUBSCRIPTION_PRICE_ID ?? ""
        ),
      onClickYearly: () =>
        goToStripeCheckout(
          process.env.REACT_APP_STRIPE_YEARLY_SUBSCRIPTION_PRICE_ID ?? ""
        ),
    },
  ];

  const goToStripeCheckout = (priceId: string) => {
    setLoading(true);
    api
      .getStripeCheckoutSessionUrl(priceId)
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        } else {
          // Throw error
          setError(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(
          "Error occurred while trying to get stripe checkout session URL -",
          err
        );
        // Throw error
        setError(true);
      });
  };

  const goToManageSubscriptionPortal = () => {
    setLoading(true);
    api
      .getStripePortalSessionUrl()
      .then((res) => {
        window.location.assign(res.data.url);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const closeSnackbar = () => {
    setError(false);
    setAccountDeleteSuccess(false);
    setAccountDeleteError(false);
  };

  return (
    <div className="bg-white ">
      <div className="h-full max-w-7xl mx-auto py-12 sm:py-16 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 text-center">
            Subscribe to Tressel
          </h1>
          <p className="mt-5 text-xl text-gray-500 text-center">
            Get started with a 7-day free trial today!
          </p>
          <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            <button
              disabled={loading}
              type="button"
              onClick={() => setBillingType("monthly")}
              className={classNames(
                billingType === "monthly"
                  ? "relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap sm:w-auto sm:px-8"
                  : "relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap sm:w-auto sm:px-8",
                "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
              )}
            >
              Monthly billing
            </button>
            <button
              disabled={loading}
              type="button"
              onClick={() => setBillingType("yearly")}
              className={classNames(
                billingType === "yearly"
                  ? "ml-0.5 relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap sm:w-auto sm:px-8"
                  : "ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap sm:w-auto sm:px-8",
                "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
              )}
            >
              Yearly billing
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 max-w-xl mx-auto">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    $
                    {billingType === "monthly"
                      ? tier.priceMonthly
                      : tier.priceYearly}
                  </span>{" "}
                  <span className="text-base font-medium text-gray-500">
                    /mo
                  </span>
                </p>
                <button
                  disabled={
                    loading || (alreadySubscribed && tier.name !== "Free")
                  }
                  onClick={() =>
                    billingType === "monthly"
                      ? tier.onClickMonthly()
                      : tier.onClickYearly()
                  }
                  className="cursor-pointer mt-8 pmt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900 disabled:cursor-default disabled:bg-gray-200 disabled:text-gray-400 disabled:border-none"
                >
                  {loading
                    ? "Loading..."
                    : alreadySubscribed
                    ? tier.name !== "Free"
                      ? "Already Subscribed"
                      : "Go Free"
                    : tier.name !== "Free"
                    ? "Start your 7-day free trial"
                    : "Stay Free"}
                </button>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="w-100 flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:space-x-2 mx-auto text-center sm:justify-center mt-8">
          <button
            onClick={signOut}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1.5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Sign out
          </button>
          {!alreadySubscribed && (
            <button
              disabled={loading || alreadySubscribed}
              onClick={() => setOpenDeleteModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
            >
              <TrashIcon className="h-5 w-5 mr-1.5" />
              Delete Account
            </button>
          )}
        </div>
        <Snackbar open={error} onClose={closeSnackbar} autoHideDuration={2000}>
          <Alert severity="error" sx={{ width: "100%" }}>
            An error occurred while redirecting to Stripe checkout - please try
            again or contact support
          </Alert>
        </Snackbar>
        <DeleteModal
          action={() => deleteUser()}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={"Are you sure you want to delete your account?"}
          description={
            "Account deletion is permanent. You won't be able to recover your account or data after deletion."
          }
        />
        <Snackbar
          open={accountDeleteSuccess}
          onClose={closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Account successfully deleted
          </Alert>
        </Snackbar>
        <Snackbar
          open={accountDeleteError}
          onClose={closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            An error occurred - please try again or contact support
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
