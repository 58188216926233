import { DuplicateIcon, KeyIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button, InstructionStep, InstructionSteps } from "theme";
import ObsidianIcon from "../../../assets/img/obsidian-icon.png";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { CopyTokenText } from "theme/copy-token-text";

export default function ObsidianSettings() {
  const auth = useAuth();
  const [error, setError] = useState<boolean>(false);
  const [loadingClearSync, setLoadingClearSync] = useState<boolean>(false);

  const [youreDoneStepStatus, setYoureDoneStepStatus] = useState(false);
  const [successClearSync, setSuccessClearSync] = useState<boolean>(false);

  const { isLoading, data: token } = useQuery({
    queryKey: [api],
    queryFn: () => api.getUserAccessToken().then((resp) => resp.data?.token),
  });

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
    }
  }, [auth.loading]);

  const clearSyncMemory = async () => {
    setLoadingClearSync(true);
    try {
      await api.clearObsidianSyncMemory();
      setSuccessClearSync(true);
    } catch (error) {
      setError(true);
    }
    setLoadingClearSync(false);
  };

  function closeSnackbar() {
    setSuccessClearSync(false);
    setError(false);
  }

  function toggleYoureDoneStepStatus() {
    setYoureDoneStepStatus(!youreDoneStepStatus);
  }

  return (
    <div className="min-h-full">
      <NavBar />
      <main className="py-10">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src={ObsidianIcon}
                  alt=""
                />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Obsidian</h1>
              <p className="text-sm font-medium text-gray-500">
                Sync to a folder in your Obsidian vault
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="notion-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="notion-information-title"
                    className="text-lg leading-6 font-bold text-gray-900"
                  >
                    Instructions
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    How to get started exporting to Obsidian
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <nav aria-label="Progress">
                    <InstructionSteps>
                      <InstructionStep
                        name="Install the Tressel plugin for Obsidian"
                        step={1}
                      >
                        <div style={{ height: 12 }} />
                        <Button
                          target="_blank"
                          href="https://obsidian.md/plugins?id=obsidian-tressel"
                        >
                          Install Tressel Plugin
                        </Button>
                      </InstructionStep>

                      <InstructionStep name="Copy your personal token" step={2}>
                        <p>
                          Copy your personal token below and paste it into the
                          Obsidian plugin's settings
                        </p>
                        <CopyTokenText value={token} />
                      </InstructionStep>

                      <InstructionStep name="You're done!" step={3}>
                        Your imported content will be automatically exported
                        every time you open Obsidian
                      </InstructionStep>
                    </InstructionSteps>
                  </nav>
                </div>
                <div className="flex flex-col items-start space-y-4 border-t border-gray-200 px-4 py-5 sm:px-6">
                  <button
                    type="button"
                    onClick={clearSyncMemory}
                    disabled={loadingClearSync}
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-50"
                  >
                    ✨ Clear Sync Memory
                  </button>
                  <p>
                    Press the <strong>Clear Sync Memory</strong> button to
                    resync all imported content into Obsidian from scratch
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Snackbar
          open={successClearSync}
          onClose={closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Sync memory successfully cleared!
          </Alert>
        </Snackbar>
        <Snackbar open={error} onClose={closeSnackbar} autoHideDuration={3000}>
          <Alert severity="error" sx={{ width: "100%" }}>
            An error occurred - please try again or contact support
          </Alert>
        </Snackbar>
      </main>
    </div>
  );
}
