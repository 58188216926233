import { useEffect, useState } from "react";
import EvernoteIcon from "../../../assets/img/evernote-icon.png";
import HackerNewsIcon from "../../../assets/img/hn-icon.png";
import HypothesisIcon from "../../../assets/img/hypothesis-icon.png";
import InstapaperIcon from "../../../assets/img/instapaper-icon.png";
import KeyIcon from "../../../assets/img/key-icon.png";
import TwitterIcon from "../../../assets/img/twitter-icon.png";
import KindleIcon from "../../../assets/img/kindle-icon.png";
import NotionIcon from "../../../assets/img/notion-icon.png";
import ObsidianIcon from "../../../assets/img/obsidian-icon.png";
import PocketIcon from "../../../assets/img/pocket-icon.png";
import QuickCaptureIcon from "../../../assets/img/quick-capture-icon.jpg";
import RaindropIcon from "../../../assets/img/raindrop-icon.png";
import RedditIcon from "../../../assets/img/reddit-icon.png";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import IntegrationCard from "../../IntegrationCard/IntegrationCard";

export default function IntegrationSettings() {
  const auth = useAuth();
  const [twitterAuthenticated, setTwitterAuthenticated] =
    useState<boolean>(false);
  const [redditAuthenticated, setRedditAuthenticated] =
    useState<boolean>(false);
  const [pocketAuthenticated, setPocketAuthenticated] =
    useState<boolean>(false);
  const [hnAuthenticated, setHNAuthenticated] = useState<boolean>(false);
  const [instapaperAuthenticated, setInstapaperAuthenticated] =
    useState<boolean>(false);
  const [notionAuthenticated, setNotionAuthenticated] =
    useState<boolean>(false);
  const [evernoteAuthenticated, setEvernoteAuthenticated] =
    useState<boolean>(false);
  const [raindropAuthenticated, setRaindropAuthenticated] =
    useState<boolean>(false);
  const [hypothesisAuthenticated, setHypothesisAuthenticated] =
    useState<boolean>(false);

  const getUserTwitterAuthenticationStatus = async () => {
    try {
      const twitterAuthStatus = await api.getUserTwitterAuthenticationStatus();
      setTwitterAuthenticated(twitterAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Twitter authenticated - ", error);
    }
  };

  const getUserRedditAuthenticationStatus = async () => {
    try {
      const redditAuthStatus = await api.getUserRedditAuthenticationStatus();
      setRedditAuthenticated(redditAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Reddit authenticated - ", error);
    }
  };

  const getUserPocketAuthenticationStatus = async () => {
    try {
      const pocketAuthStatus = await api.getUserPocketAuthenticationStatus();
      setPocketAuthenticated(pocketAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Pocket authenticated - ", error);
    }
  };

  const getUserHNAuthenticationStatus = async () => {
    try {
      const hnUsername = await api.getUserHNUsername();
      if (hnUsername.data.username) {
        setHNAuthenticated(true);
      } else {
        setHNAuthenticated(false);
      }
    } catch (error) {
      console.error("Error checking if HN authenticated - ", error);
    }
  };

  const getUserInstapaperAuthenticationStatus = async () => {
    try {
      const instapaperAuthStatus =
        await api.getUserInstapaperAuthenticationStatus();
      setInstapaperAuthenticated(instapaperAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Instapaper authenticated - ", error);
    }
  };

  const getUserNotionAuthenticationStatus = async () => {
    try {
      const notionAuthStatus = await api.getUserNotionAuthenticationStatus();
      setNotionAuthenticated(notionAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Notion authenticated - ", error);
    }
  };

  const getUserEvernoteAuthenticationStatus = async () => {
    try {
      const evernoteAuthStatus =
        await api.getUserEvernoteAuthenticationStatus();
      setEvernoteAuthenticated(evernoteAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Evernote authenticated - ", error);
    }
  };

  const getUserRaindropAuthenticationStatus = async () => {
    try {
      const raindropAuthStatus =
        await api.getUserRaindropAuthenticationStatus();
      setRaindropAuthenticated(raindropAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Raindrop authenticated - ", error);
    }
  };

  const getUserHypothesisAuthenticationStatus = async () => {
    try {
      const hypothesisAuthStatus =
        await api.getUserHypothesisAuthenticationStatus();
      setHypothesisAuthenticated(hypothesisAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Hypothesis authenticated - ", error);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      getUserTwitterAuthenticationStatus();
      getUserRedditAuthenticationStatus();
      getUserPocketAuthenticationStatus();
      getUserHNAuthenticationStatus();
      getUserInstapaperAuthenticationStatus();
      getUserRaindropAuthenticationStatus();
      getUserHypothesisAuthenticationStatus();
      getUserNotionAuthenticationStatus();
      getUserEvernoteAuthenticationStatus();
    }
  }, [auth.loading]);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      {/* Connect your second brain */}
      <div className="py-3 px-5 sm:px-2">
        <h2
          id="export-heading"
          className="text-2xl leading-6 font-bold text-gray-900"
        >
          Connect to your second brain
        </h2>
        <div className="my-6">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Obsidian"
                description="Sync in Markdown to a folder in Obsidian"
                iconSrc={ObsidianIcon}
                href="/connect/obsidian"
                added={false}
                external
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Notion"
                description="Sync to a Notion database"
                iconSrc={NotionIcon}
                href="/connect/notion"
                added={notionAuthenticated}
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Evernote"
                description="Sync to an Evernote notebook"
                iconSrc={EvernoteIcon}
                href="/connect/evernote"
                added={evernoteAuthenticated}
              />
            </li>
          </ul>
        </div>
      </div>

      {/* Import */}
      <div className="py-3 px-5 sm:px-2">
        <h2
          id="import-heading"
          className="text-2xl leading-6 font-bold text-gray-900"
        >
          Connect sources to sync
        </h2>
        <div className="my-6">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {/* <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Twitter"
                description="Tweets, threads and conversations"
                iconSrc={TwitterIcon}
                href="/twitter"
                added={twitterAuthenticated}
              />
            </li> */}
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Reddit"
                description="Reddit posts & comments"
                iconSrc={RedditIcon}
                href="/connect/reddit"
                added={redditAuthenticated}
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Kindle"
                description="Kindle highlights"
                iconSrc={KindleIcon}
                href="/connect/kindle"
                added={false}
                external
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Pocket"
                description="Pocket highlights"
                iconSrc={PocketIcon}
                href="/connect/pocket"
                added={pocketAuthenticated}
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="HackerNews"
                description="Hacker News favourites"
                iconSrc={HackerNewsIcon}
                href="/connect/hn"
                added={hnAuthenticated}
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Instapaper"
                description="Instapaper highlights"
                iconSrc={InstapaperIcon}
                href="/connect/instapaper"
                added={instapaperAuthenticated}
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Raindrop"
                description="Raindrop highlights"
                iconSrc={RaindropIcon}
                href="/connect/raindrop"
                added={raindropAuthenticated}
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Hypothes.is"
                description="Hypothes.is annotations"
                iconSrc={HypothesisIcon}
                href="/connect/hypothesis"
                added={hypothesisAuthenticated}
              />
            </li>
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Quick Capture"
                description="Capture custom highlights"
                iconSrc={QuickCaptureIcon}
                href="/connect/quick-capture"
                added={false}
                external
              />
            </li>
          </ul>
        </div>
      </div>

      {/* General */}
      {/* <div className="py-3 px-5 sm:px-2">
        <h2
          id="general-heading"
          className="text-2xl leading-6 font-bold text-gray-900"
        >
          General
        </h2>
        <div className="my-6">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <IntegrationCard
                name="Access Token"
                description="Connect Tressel to an external source"
                iconSrc={KeyIcon}
                settingsUrl="./access-token"
                added={false}
                external
              />
            </li>
          </ul>
        </div>
      </div> */}

      {/* Integration Ideas */}
      <div className="py-2 px-5 sm:px-2">
        <div className="bg-emerald-600 sm:rounded-lg shadow border">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-bold text-gray-100">
              Want an integration you don't see here?
            </h3>
            <div className="mt-5">
              <button
                type="button"
                onClick={() =>
                  window.location.assign("https://tally.so/r/3xXV5y")
                }
                className="inline-flex items-center px-4 py-2 border border-gray-900 shadow-md font-medium rounded-md text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:text-sm"
              >
                ⚡ Request an integration
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
