/* This example requires Tailwind CSS v2.0+ */
import { CogIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

interface IntegrationCardProps {
  name: string;
  description: string;
  iconSrc: string;
  href: string;
  added: boolean;
  external?: boolean;
}

export default function IntegrationCard(props: IntegrationCardProps) {
  const navigate = useNavigate();
  const goToIntegrationSettings = () => {
    navigate(props.href);
  };

  return (
    <div className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-bold truncate">
              {props.name}
            </h3>
            {props.added && (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full fadeIn">
                Added
              </span>
            )}
            {props.external && !props.added && (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-800 text-xs font-medium bg-gray-100 rounded-full fadeIn">
                External
              </span>
            )}
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">
            {props.description}
          </p>
        </div>
        <img
          className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
          src={props.iconSrc ?? ""}
          alt=""
        />
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <a
              onClick={goToIntegrationSettings}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer"
            >
              <CogIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Configure</span>
            </a>
          </div>
          <div className="-ml-px w-0 flex-1 flex"></div>
        </div>
      </div>
    </div>
  );
}
