import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { User } from "firebase/auth";
import { UserPreferences } from "./types";

class Api {
  token: string | null;
  client: AxiosInstance;
  apiBaseUrl: string;

  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? "";
    this.token = localStorage.getItem("token");

    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response.status)) {
          window.location.href = "/signout";
        }
        return error;
      }
    );
  }

  updateClient = (newToken: string) => {
    this.token = newToken;

    // Update API
    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response.status)) {
          window.location.href = "/signout";
        }
        return error;
      }
    );

    return this.client;
  };

  // User
  setUserLastSentFeedback = () => {
    return this.client.post("/user/feedback");
  };

  completeUserOnboarding = () => {
    return this.client.post("/user/onboarding");
  };

  createUserIfDoesntExist = () => {
    return this.client.post("/user");
  };

  deleteUser = () => {
    return this.client.delete("/user");
  };

  getMyInfo = () => {
    return this.client.get("/user/me");
  };

  // Imported items
  getRecentImportedItems = (count?: number) => {
    const query = count ? "?items=" + count : "";
    return this.client.get("/imported-items/recent" + query);
  };

  // User imported items
  getUserImportedItems = (
    page: number,
    size: number,
    searchQuery: string,
    sortOrder: string,
    trashed?: boolean
  ) => {
    return this.client.get("/user/imported-items", {
      params: {
        page,
        size,
        searchQuery,
        sortOrder,
        trashed: trashed ? "true" : "false",
      },
    });
  };

  restoreUserImportedItemById = (userImportedItemId: string) => {
    return this.client.post("/user/imported-items/" + userImportedItemId);
  };

  deleteUserImportedItemById = (userImportedItemId: string) => {
    return this.client.delete("/user/imported-items/" + userImportedItemId);
  };

  getUserImportedItemCounts = () => {
    return this.client.get("/user/imported-items/count");
  };

  // Stripe
  getStripeCheckoutSessionUrl = (priceId: string) => {
    return this.client.get("/stripe/checkout", {
      params: {
        priceId: priceId,
      },
    });
  };

  getStripePortalSessionUrl = () => {
    return this.client.get("/stripe/portal");
  };

  getCustomerSubscriptions = () => {
    return this.client.get("/stripe/subscriptions");
  };

  // Preferences
  getUserPreferences = () => {
    return this.client.get("/user/preferences");
  };

  updateUserPreferences = (newUserPreferences: UserPreferences) => {
    return this.client.post("/user/preferences", {
      preferences: newUserPreferences,
    });
  };

  // Reddit
  getRedditAuthorizeUrl = () => {
    return this.client.get("/reddit/init/authorize-url");
  };

  getAndStoreRedditAccessToken = (
    state: string,
    code: string,
    sessionState: string
  ) => {
    return this.client.get("/reddit/init/access-token", {
      params: {
        state,
        code,
        sessionState,
      },
    });
  };

  getUserRedditAuthenticationStatus = () => {
    return this.client.get("/reddit/auth/status");
  };

  removeUserRedditIntegration = () => {
    return this.client.delete("/reddit/auth/remove");
  };

  syncRedditSavedItems = () => {
    return this.client.post("/reddit/sync/saved");
  };

  // Pocket
  getPocketAuthorizeUrl = () => {
    return this.client.get("/pocket/init/authorize-url");
  };

  getAndStorePocketAccessToken = (requestToken: string) => {
    return this.client.get("/pocket/init/access-token", {
      params: {
        requestToken,
      },
    });
  };

  getUserPocketAuthenticationStatus = () => {
    return this.client.get("/pocket/auth/status");
  };

  removeUserPocketIntegration = () => {
    return this.client.delete("/pocket/auth/remove");
  };

  syncPocketHighlights = () => {
    return this.client.post("/pocket/sync/highlights");
  };

  // HackerNews
  getUserHNUsername = () => {
    return this.client.get("/hackernews/auth/username");
  };

  setUserHNUsername = (newUsername: string) => {
    return this.client.post("/hackernews/auth/username", {
      hackerNewsUsername: newUsername,
    });
  };

  removeUserHNIntegration = () => {
    return this.client.delete("/hackernews/auth/remove");
  };

  // Twitter
  getTwitterAuthorizeUrl = () => {
    return this.client.get("/twitter/init/authorize-url");
  };

  getAndStoreTwitterAccessToken = (
    state: string,
    code: string,
    codeVerifier: string,
    sessionState: string
  ) => {
    return this.client.get("/twitter/init/access-token", {
      params: {
        state,
        code,
        codeVerifier,
        sessionState,
      },
    });
  };

  getUserTwitterAuthenticationStatus = () => {
    return this.client.get("/twitter/auth/status");
  };

  removeUserTwitterIntegration = () => {
    return this.client.delete("/twitter/auth/remove");
  };

  syncTwitterBookmarks = () => {
    return this.client.post("/twitter/sync/bookmarks");
  };

  // Evernote
  getEvernoteRequestToken = () => {
    return this.client.get("/evernote/init/request-token");
  };

  getEvernoteAccessToken = (
    oauthToken: string,
    oauthSecret: string,
    oauthVerifier: string
  ) => {
    return this.client.get("/evernote/init/access-token", {
      params: {
        oauthToken: oauthToken,
        oauthSecret: oauthSecret,
        oauthVerifier: oauthVerifier,
      },
    });
  };

  getEvernoteAccessTokenFromDb = () => {
    return this.client.get("/evernote/db/access-token");
  };

  setEvernoteAccessTokenInDb = (newToken: string) => {
    return this.client.post("/evernote/db/access-token", {
      evernoteAccessToken: newToken,
    });
  };

  clearEvernoteSyncMemory = () => {
    return this.client.get("/evernote/clear-sync-memory");
  };

  getUserEvernoteAuthenticationStatus = () => {
    return this.client.get("/evernote/auth/status");
  };

  removeUserEvernoteIntegration = () => {
    return this.client.delete("/evernote/auth/remove");
  };

  // Raindrop
  getAndSetRaindropRefreshToken = (code: string) => {
    return this.client.post("/raindrop/init/refresh-token", {
      code: code,
    });
  };

  getRaindropRefreshTokenFromDb = () => {
    return this.client.get("/raindrop/db/refresh-token");
  };

  getUserRaindropAuthenticationStatus = () => {
    return this.client.get("/raindrop/auth/status");
  };

  removeUserRaindropIntegration = () => {
    return this.client.delete("/raindrop/auth/remove");
  };

  syncRaindropHighlights = () => {
    return this.client.post("/raindrop/sync/highlights");
  };

  syncRaindrops = () => {
    return this.client.post("/raindrop/sync/raindrops");
  };

  // Instapaper
  setInstapaperOauthCredentials = (username: string, password: string) => {
    return this.client.post("/instapaper/init/oauth-credentials", {
      username: username,
      password: password,
    });
  };

  getInstapaperOauthCredentialsFromDb = () => {
    return this.client.get("/instapaper/db/oauth-credentials");
  };

  getUserInstapaperAuthenticationStatus = () => {
    return this.client.get("/instapaper/auth/status");
  };

  removeUserInstapaperIntegration = () => {
    return this.client.delete("/instapaper/auth/remove");
  };

  syncInstapaperHighlights = () => {
    return this.client.post("/instapaper/sync/highlights");
  };

  // Hypothesis
  getHypothesisApiKey = () => {
    return this.client.get("/hypothesis/api-key");
  };

  setHypothesisApiKey = (hypothesisApiKey: string) => {
    return this.client.post("/hypothesis/api-key", {
      hypothesisApiKey: hypothesisApiKey,
    });
  };

  getUserHypothesisAuthenticationStatus = () => {
    return this.client.get("/hypothesis/auth/status");
  };

  removeUserHypothesisIntegration = () => {
    return this.client.delete("/hypothesis/auth/remove");
  };

  syncHypothesisAnnotations = () => {
    return this.client.post("/hypothesis/sync/annotations");
  };

  // Notion
  getNotionApiKey = () => {
    return this.client.get("/notion/api-key");
  };

  setNotionApiKey = (notionApiKey: string) => {
    return this.client.post("/notion/api-key", { notionApiKey: notionApiKey });
  };

  clearNotionSyncMemory = () => {
    return this.client.get("/notion/clear-sync-memory");
  };

  getUserNotionAuthenticationStatus = () => {
    return this.client.get("/notion/auth/status");
  };

  removeUserNotionIntegration = () => {
    return this.client.delete("/notion/auth/remove");
  };

  // Obsidian
  clearObsidianSyncMemory = () => {
    return this.client.get("/obsidian/clear-sync-memory");
  };

  // Access Token
  getUserAccessToken = () => {
    return this.client.get("/token");
  };
}

export default new Api();
