import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./PublicTweet.module.css";

export const PublicTweet = () => {
  const { tweetId } = useParams();

  useEffect(() => {
    window.location.assign(`https://tressel.xyz/tweets/${tweetId}`);
  }, []);

  return <div className={styles.greyBackground}>Redirecting...</div>;
};
