import { AppSidebar } from "components/AppSidebar";
import IntegrationSettings from "components/Settings/IntegrationSettings/IntegrationSettings";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { MasterDetail } from "theme";
import AccountSettings from "./components/Settings/AccountSettings/AccountSettings";
import SubscriptionSettings from "./components/Settings/SubscriptionSettings/SubscriptionSettings";
import ChooseSubscription from "./pages/ChooseSubscription/ChooseSubscription";
import AccessTokenSettings from "./pages/Settings/AccessTokenSettings/AccessTokenSettings";
import EvernoteSettings from "./pages/Settings/EvernoteSettings/EvernoteSettings";
import HackerNewsSettings from "./pages/Settings/HackerNewsSettings/HackerNewsSettings";
import HypothesisSettings from "./pages/Settings/HypothesisSettings/HypothesisSettings";
import InstapaperSettings from "./pages/Settings/InstapaperSettings/InstapaperSettings";
import KindleSettings from "./pages/Settings/KindleSettings/KindleSettings";
import NotionSettings from "./pages/Settings/NotionSettings/NotionSettings";
import ObsidianSettings from "./pages/Settings/ObsidianSettings/ObsidianSettings";
import PocketSettings from "./pages/Settings/PocketSettings/PocketSettings";
import QuickCaptureSettings from "./pages/Settings/QuickCaptureSettings/QuickCaptureSettings";
import RaindropSettings from "./pages/Settings/RaindropSettings/RaindropSettings";
import RedditSettings from "./pages/Settings/RedditSettings/RedditSettings";
import TwitterSettings from "./pages/Settings/TwitterSettings/TwitterSettings";
import SignIn from "./pages/SignIn/SignIn";
import SignOut from "./pages/SignOut/SignOut";
import SignUp from "./pages/SignUp/SignUp";
import { PublicThread } from "./pages/public/PublicThread/PublicThread";
import { PublicTweet } from "./pages/public/PublicTweet/PublicTweet";
import {
  AuthProvider,
  RequireAuth,
  RequireSubscription,
} from "./utils/auth/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/public/tweet/">
              <Route path=":tweetId" element={<PublicTweet />} />
            </Route>
            <Route path="/public/thread/">
              <Route path=":threadId" element={<PublicThread />} />
            </Route>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signout" element={<SignOut />} />

            <Route
              path="/choose-subscription"
              element={
                <RequireAuth>
                  <ChooseSubscription />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <RequireSubscription>
                    <MasterDetail sidebar={<AppSidebar />}>
                      <IntegrationSettings />
                    </MasterDetail>
                  </RequireSubscription>
                </RequireAuth>
              }
            />

            <Route path="/account" element={<AccountSettings />} />

            <Route
              path="/subscription"
              element={
                <RequireAuth>
                  <SubscriptionSettings />
                </RequireAuth>
              }
            />

            <Route path="/connect">
              <Route
                path="twitter"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <TwitterSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="reddit"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <RedditSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="kindle"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <KindleSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="pocket"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <PocketSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="hn"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <HackerNewsSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="instapaper"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <InstapaperSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="raindrop"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <RaindropSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="hypothesis"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <HypothesisSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="quick-capture"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <QuickCaptureSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="notion"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <NotionSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="obsidian"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <ObsidianSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="evernote"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <EvernoteSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
              <Route
                path="access-token"
                element={
                  <RequireAuth>
                    <RequireSubscription>
                      <AccessTokenSettings />
                    </RequireSubscription>
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="/" element={<Navigate to="/signin" />} />
          </Routes>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}
