import { DuplicateIcon, HomeIcon, KeyIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import KeyImage from "../../../assets/img/key-icon.png";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";

export default function AccessTokenSettings() {
  const auth = useAuth();
  const [accessToken, setAccessToken] = useState<string>("");
  const [loadingToken, setLoadingToken] = useState<boolean>(true);
  const [successTokenCopied, setSuccessTokenCopied] = useState<boolean>(false);

  const getToken = async () => {
    try {
      const tokenRes = await api.getUserAccessToken();
      if (tokenRes.status === 200) {
        setAccessToken(
          tokenRes.data.token ? tokenRes.data.token : "Token not found"
        );
        setLoadingToken(false);
      } else {
        console.error(
          "Error while getting user access token -",
          tokenRes.data.message
        );
      }
    } catch (error) {
      console.error("Error while getting user access token -", error);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      getToken();
    }
  }, [auth.loading]);

  const copyTokenToClipboard = () => {
    navigator.clipboard.writeText(accessToken);
    setSuccessTokenCopied(true);
  };

  const closeSnackbar = () => {
    setSuccessTokenCopied(false);
  };

  return (
    <>
      <div className="min-h-full">
        <NavBar />
        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={KeyImage}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Access Token
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  For connecting Tressel to an external source (like the Chrome
                  extension or Obsidian plugin)
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="notion-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="border-gray-200 px-4 py-5 sm:px-6">
                    <div>
                      <label
                        htmlFor="accessToken"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Access Token
                      </label>
                      <div className="bg-white mt-1 relative rounded-md shadow-sm border-2 flex items-stretch flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <KeyIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="key"
                          name="accessToken"
                          id="accessToken"
                          value={
                            loadingToken ? "Loading token..." : accessToken
                          }
                          disabled={true}
                          className="p-2 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300 cursor-copy"
                        />
                        <button
                          type="button"
                          disabled={loadingToken}
                          onClick={copyTokenToClipboard}
                          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-md bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-50"
                        >
                          <DuplicateIcon
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Snackbar
            open={successTokenCopied}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Token copied to clipboard!
            </Alert>
          </Snackbar>
        </main>
      </div>
    </>
  );
}
