import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./PublicThread.module.css";

export const PublicThread = () => {
  const { threadId } = useParams();

  useEffect(() => {
    window.location.assign(`https://tressel.xyz/threads/${threadId}`);
  }, []);

  return <div className={styles.greyBackground}>Redirecting...</div>;
};
