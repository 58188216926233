import { CheckIcon, HomeIcon, KeyIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HypothesisIcon from "../../../assets/img/hypothesis-icon.png";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";

export default function HypothesisSettings() {
  const auth = useAuth();
  const [hypothesisApiKey, setHypothesisApiKey] = useState<string>("");
  const [noInitialApiKeySet, setNoInitialApiKeySet] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [removeIntegrationSuccess, setRemoveIntegrationSuccess] =
    useState<boolean>(false);
  const [syncAnnotationsSuccess, setSyncAnnotationsSuccess] =
    useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [getApiTokenStepStatus, setGetApiTokenStepStatus] = useState(false);
  const [copyTokenStepStatus, setCopyTokenStepStatus] = useState(false);
  const [youreDoneStepStatus, setYoureDoneStepStatus] = useState(false);
  const [openRemoveIntegrationModal, setOpenRemoveIntegrationModal] =
    useState<boolean>(false);

  const getApiKey = async () => {
    const hypothesisApiKeyRes = await api.getHypothesisApiKey();
    if (hypothesisApiKeyRes.status === 200) {
      setHypothesisApiKey(hypothesisApiKeyRes.data.apiKey);
      if (hypothesisApiKeyRes.data.apiKey !== "") {
        setNoInitialApiKeySet(false);
        setGetApiTokenStepStatus(true);
        setCopyTokenStepStatus(true);
        setYoureDoneStepStatus(true);
      }
    } else {
      setHypothesisApiKey("");
      setNoInitialApiKeySet(true);
    }
    setLoading(false);
  };

  const updateHypothesisApiKey = async () => {
    setLoading(true);
    try {
      await api.setHypothesisApiKey(hypothesisApiKey);
      setSuccess(true);
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  const closeSnackbar = () => {
    setSuccess(false);
    setRemoveIntegrationSuccess(false);
    setError(false);
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      getApiKey();
    }
  }, [auth.loading]);

  const toggleCreateIntegrationStepStatus = () => {
    setGetApiTokenStepStatus(!getApiTokenStepStatus);
  };

  const toggleCopyTokenStepStatus = () => {
    setCopyTokenStepStatus(!copyTokenStepStatus);
  };

  const toggleYoureDoneStepStatus = () => {
    setYoureDoneStepStatus(!youreDoneStepStatus);
  };

  const removeIntegration = () => {
    api
      .removeUserHypothesisIntegration()
      .then(() => {
        setRemoveIntegrationSuccess(true);
        window.location.reload();
      })
      .catch((error) => {
        setError(true);
        console.error("Error removing integration - ", error);
      });
  };

  const syncHypothesisAnnotations = async () => {
    setLoading(true);
    try {
      await api.syncHypothesisAnnotations();
      setSyncAnnotationsSuccess(true);
    } catch (error) {
      setError(true);
      console.error("Error syncing Raindrop highlights - ", error);
    }
    setLoading(false);
  };

  const steps = [
    {
      name: "Get your personal Hypothes.is API Token",
      description:
        "Go to https://hypothes.is/account/developer and generate an API token",
      onClick: toggleCreateIntegrationStepStatus,
      status: getApiTokenStepStatus ? "complete" : "current",
    },
    {
      name: "Copy your token here",
      description: "Copy the 'API Token' and enter it below.",
      onClick: toggleCopyTokenStepStatus,
      status: copyTokenStepStatus ? "complete" : "upcoming",
    },
    {
      name: "You're done!",
      description:
        "Your annotations will be automatically imported to Tressel periodically",
      onClick: toggleYoureDoneStepStatus,
      status: youreDoneStepStatus ? "complete" : "upcoming",
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <NavBar />

        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={HypothesisIcon}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Hypothesis</h1>
                <p className="text-sm font-medium text-gray-500">
                  Sync your Hypothes.is annotations
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="hypothesis-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="hypothesis-information-title"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Instructions
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      How to get started importing your Hypothes.is annotations
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <nav aria-label="Progress">
                      <ol role="list" className="overflow-hidden">
                        {steps.map((step, stepIdx) => (
                          <li
                            key={step.name}
                            className={classNames(
                              stepIdx !== steps.length - 1 ? "pb-10" : "",
                              "relative"
                            )}
                          >
                            {step.status === "complete" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="cursor-pointer relative flex items-start group"
                                >
                                  <span className="h-9 flex items-center">
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                      <CheckIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : step.status === "current" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                  aria-current="step"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                                      <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            )}
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div>
                      <label
                        htmlFor="hypothesisApiKey"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Hypothesis API Token
                      </label>
                      <div className="bg-white mt-1 relative rounded-md shadow-sm border-2 flex items-stretch flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <KeyIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="key"
                          name="hypothesisApiKey"
                          id="hypothesisApiKey"
                          value={
                            loading ? "Loading API Token..." : hypothesisApiKey
                          }
                          onChange={(e) =>
                            setHypothesisApiKey(e.currentTarget.value)
                          }
                          disabled={loading}
                          placeholder={"API Token"}
                          className="p-2 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300 disabled:opacity-50"
                        />
                        <button
                          type="button"
                          disabled={loading}
                          onClick={updateHypothesisApiKey}
                          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-blue-600 text-sm font-medium rounded-r-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-50"
                        >
                          <span>Save</span>
                        </button>
                      </div>
                      {!loading &&
                        hypothesisApiKey === "Invalid Hypothesis API Key" && (
                          <p className="mt-2 text-sm text-red-600">
                            Please enter a valid API token
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </section>
              {hypothesisApiKey && !noInitialApiKeySet && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Sync all annotations
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This syncs all of your Hypothes.is annotations. It
                            will skip duplicate/trashed annotations.
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => syncHypothesisAnnotations()}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                      >
                        Sync
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {hypothesisApiKey && !noInitialApiKeySet && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Remove integration
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This removes Tressel access (but not any existing
                            data from the integration)
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => setOpenRemoveIntegrationModal(true)}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        Remove Integration
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={success}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              API token successfully updated
            </Alert>
          </Snackbar>
          <Snackbar
            open={removeIntegrationSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Successfully removed integration!
            </Alert>
          </Snackbar>
          <Snackbar
            open={syncAnnotationsSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Started syncing annotations! Syncing will continue in the
              background
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              An error occurred - please try again or contact support
            </Alert>
          </Snackbar>
        </main>
      </div>
      <DeleteModal
        action={() => removeIntegration()}
        open={openRemoveIntegrationModal}
        setOpen={setOpenRemoveIntegrationModal}
        title={"Are you sure you want to remove this integration?"}
        description={
          "Your existing data from the integration won't be removed but you'll have to set it up again after removal."
        }
      />
    </>
  );
}
