import { CheckIcon, HomeIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RaindropIcon from "../../../assets/img/raindrop-icon.png";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import "url-search-params-polyfill";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";

export default function RaindropSettings() {
  const auth = useAuth();
  const [success, setSuccess] = useState<boolean>(false);
  const [removeIntegrationSuccess, setRemoveIntegrationSuccess] =
    useState<boolean>(false);
  const [syncHighlightsSuccess, setSyncHighlightsSuccess] =
    useState<boolean>(false);
  const [syncRaindropsSuccess, setSyncRaindropsSuccess] =
    useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loginWithRaindropStepStatus, setLoginWithRaindropStepStatus] =
    useState(false);
  const [youreDoneStepStatus, setYoureDoneStepStatus] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);

  const [raindropAuthenticated, setRaindropAuthenticated] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [openRemoveIntegrationModal, setOpenRemoveIntegrationModal] =
    useState<boolean>(false);

  const loginWithRaindrop = async () => {
    try {
      window.location.href = `https://api.raindrop.io/v1/oauth/authorize?client_id=${
        process.env.REACT_APP_RAINDROP_CLIENT_ID
      }&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_RAINDROP_CALLBACK_URL ?? ""
      )}`;
    } catch (error) {
      console.error("Error while trying to log in with raindrop -", error);
      setError(true);
    }
  };

  const closeSnackbar = () => {
    setSuccess(false);
    setRemoveIntegrationSuccess(false);
    setError(false);
  };

  const retrieveRefreshToken = async () => {
    // Workflow for retrieving access token from Raindrop and storing it in db
    const code = queryParams.get("code");

    if (code) {
      // Get access token
      const accessTokenRes = await api.getAndSetRaindropRefreshToken(code);
      if (accessTokenRes.status === 200) {
        setSuccess(true);
      } else {
        setError(true);
        console.error(
          "Error getting Raindrop access token -",
          accessTokenRes.data
        );
      }
    }

    // Workflow for retrieving Raindrop access token already stored in our db
    try {
      const raindropRefreshToken = await api.getRaindropRefreshTokenFromDb();
      setLoading(false);
      if (raindropRefreshToken.data.token) {
        setRaindropAuthenticated(true);
        setLoginWithRaindropStepStatus(true);
        setYoureDoneStepStatus(true);
      }
    } catch (error) {
      console.error("Error retrieving raindrop access token from db - ", error);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      retrieveRefreshToken();
    }
  }, [auth.loading]);

  const toggleLoginWithRaindropStepStatus = () => {
    setLoginWithRaindropStepStatus(!loginWithRaindropStepStatus);
  };

  const toggleYoureDoneStepStatus = () => {
    setYoureDoneStepStatus(!youreDoneStepStatus);
  };

  const removeIntegration = () => {
    api
      .removeUserRaindropIntegration()
      .then(() => {
        setRemoveIntegrationSuccess(true);
        window.location.reload();
      })
      .catch((error) => {
        setError(true);
        console.error("Error removing integration - ", error);
      });
  };

  const syncRaindropHighlights = async () => {
    setLoading(true);
    try {
      await api.syncRaindropHighlights();
      setSyncHighlightsSuccess(true);
    } catch (error) {
      setError(true);
      console.error("Error syncing Raindrop highlights - ", error);
    }
    setLoading(false);
  };

  const syncRaindrops = async () => {
    setLoading(true);
    try {
      await api.syncRaindrops();
      setSyncRaindropsSuccess(true);
    } catch (error) {
      setError(true);
      console.error("Error syncing Raindrops - ", error);
    }
    setLoading(false);
  };

  const steps = [
    {
      name: "Login with Raindrop",
      description:
        "Authenticate Raindrop for Tressel using the login button below",
      onClick: toggleLoginWithRaindropStepStatus,
      status: loginWithRaindropStepStatus ? "complete" : "current",
    },
    {
      name: "You're done!",
      description:
        "Your Raindrops, highlights and notes will be automatically synced to Tressel periodically",
      onClick: toggleYoureDoneStepStatus,
      status: youreDoneStepStatus ? "complete" : "upcoming",
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <NavBar />

        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={RaindropIcon}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Raindrop</h1>
                <p className="text-sm font-medium text-gray-500">
                  Sync your Raindrop highlights (and saved raindrops)
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="notion-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notion-information-title"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Instructions
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      How to get started syncing your Raindrop highlights
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <nav aria-label="Progress">
                      <ol role="list" className="overflow-hidden">
                        {steps.map((step, stepIdx) => (
                          <li
                            key={step.name}
                            className={classNames(
                              stepIdx !== steps.length - 1 ? "pb-10" : "",
                              "relative"
                            )}
                          >
                            {step.status === "complete" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="cursor-pointer relative flex items-start group"
                                >
                                  <span className="h-9 flex items-center">
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                      <CheckIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : step.status === "current" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                  aria-current="step"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                                      <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            )}
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {loading ? (
                      <p>Checking if you're already authenticated...</p>
                    ) : (
                      <button
                        type="button"
                        onClick={loginWithRaindrop}
                        className="inline-flex bg-black items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <img className="w-5 h-5 mr-2" src={RaindropIcon} />
                        {raindropAuthenticated
                          ? "Reauthenticate Raindrop"
                          : "Login with Raindrop"}
                      </button>
                    )}
                  </div>
                  {raindropAuthenticated && (
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div>
                        <p>
                          You're already authenticated, but you may need to
                          reauthenticate if your access token expires
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </section>
              {raindropAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Sync all highlights
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This syncs all of your Raindrop highlights. It will
                            skip duplicate/trashed highlights.
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => syncRaindropHighlights()}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                      >
                        Sync
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {raindropAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Sync all raindrops
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This syncs all of your Raindrops (links/bookmarks).
                            It will skip duplicate/trashed raindrops.
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => syncRaindrops()}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                      >
                        Sync
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {raindropAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Remove integration
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This removes Tressel access (but not any existing
                            data from the integration)
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => setOpenRemoveIntegrationModal(true)}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        Remove Integration
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={success}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Successfully authenticated Raindrop
            </Alert>
          </Snackbar>
          <Snackbar
            open={removeIntegrationSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Successfully removed integration!
            </Alert>
          </Snackbar>
          <Snackbar
            open={syncHighlightsSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Started syncing highlights! Syncing will continue in the
              background
            </Alert>
          </Snackbar>
          <Snackbar
            open={syncRaindropsSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Started syncing raindrops! Syncing will continue in the background
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              An error occurred - please try again or contact support
            </Alert>
          </Snackbar>
        </main>
      </div>
      <DeleteModal
        action={() => removeIntegration()}
        open={openRemoveIntegrationModal}
        setOpen={setOpenRemoveIntegrationModal}
        title={"Are you sure you want to remove this integration?"}
        description={
          "Your existing data from the integration won't be removed but you'll have to set it up again after removal."
        }
      />
    </>
  );
}
