import {
  ArrowCircleUpIcon,
  CheckIcon,
  DuplicateIcon,
  HomeIcon,
  KeyIcon,
} from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import QuickCaptureIcon from "../../../assets/img/quick-capture-icon.jpg";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";

export default function QuickCaptureSettings() {
  const auth = useAuth();
  const [error, setError] = useState<boolean>(false);
  const [
    installTresselChromeExtensionStepStatus,
    setInstallTresselChromeExtensionStepStatus,
  ] = useState(false);
  const [copyPersonalTokenStepStatus, setCopyPersonalTokenStepStatus] =
    useState(false);
  const [youreDoneStepStatus, setYoureDoneStepStatus] = useState(false);
  const [accessToken, setAccessToken] = useState<string>("");
  const [loadingToken, setLoadingToken] = useState<boolean>(true);
  const [successTokenCopied, setSuccessTokenCopied] = useState<boolean>(false);
  const [subscribed, setSubscribed] = useState<boolean>(true);

  const getToken = async () => {
    try {
      const tokenRes = await api.getUserAccessToken();
      if (tokenRes.status === 200) {
        setAccessToken(
          tokenRes.data.token ? tokenRes.data.token : "Token not found"
        );
        setLoadingToken(false);
      } else {
        console.error(
          "Error while getting user access token -",
          tokenRes.data.message
        );
      }
    } catch (error) {
      console.error("Error while getting user access token -", error);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      getToken();
      api
        .getCustomerSubscriptions()
        .then((subscriptions) => {
          if (subscriptions.data.subscriptions.length > 0) {
            for (const subscription of subscriptions.data.subscriptions) {
              if (
                subscription.status === "active" ||
                subscription.status === "trialing"
              ) {
                setSubscribed(true);
                return;
              }
            }
          } else {
            setSubscribed(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [auth.loading]);

  const copyTokenToClipboard = () => {
    navigator.clipboard.writeText(accessToken);
    setSuccessTokenCopied(true);
  };

  const closeSnackbar = () => {
    setSuccessTokenCopied(false);
    setError(false);
  };

  const toggleCopyPersonalTokenStepStatus = () => {
    setCopyPersonalTokenStepStatus(!copyPersonalTokenStepStatus);
  };

  const toggleInstallTresselChromeExtensionStepStatus = () => {
    setInstallTresselChromeExtensionStepStatus(
      !installTresselChromeExtensionStepStatus
    );
  };

  const toggleYoureDoneStepStatus = () => {
    setYoureDoneStepStatus(!youreDoneStepStatus);
  };

  const steps = [
    {
      name: "Install the Tressel Chrome extension",
      description: "You can find it in the Chrome Web Store",
      onClick: toggleInstallTresselChromeExtensionStepStatus,
      status: installTresselChromeExtensionStepStatus ? "complete" : "upcoming",
    },
    {
      name: "Copy your personal token",
      description:
        "Find your unique token below and paste it into the Chrome extension's popup settings (visible by clicking on the Tressel extension icon)",
      onClick: toggleCopyPersonalTokenStepStatus,
      status: copyPersonalTokenStepStatus ? "complete" : "upcoming",
    },
    {
      name: "You're done!",
      description:
        "You can quickly capture highlights by opening the Chrome extension popup or highlighting text and right-clicking to send to Tressel",
      onClick: toggleYoureDoneStepStatus,
      status: youreDoneStepStatus ? "complete" : "upcoming",
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <NavBar />
        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={QuickCaptureIcon}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Quick Capture
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  Quickly capture highlights in your browser via the Tressel
                  Chrome Extension (for premium users only)
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="notion-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notion-information-title"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Instructions
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      How to get started quickly capturing highlights from your
                      browser
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <nav aria-label="Progress">
                      <ol role="list" className="overflow-hidden">
                        {steps.map((step, stepIdx) => (
                          <li
                            key={step.name}
                            className={classNames(
                              stepIdx !== steps.length - 1 ? "pb-10" : "",
                              "relative"
                            )}
                          >
                            <a
                              onClick={step.onClick}
                              className="relative flex items-start group"
                              aria-current="step"
                            >
                              <span className="min-w-0 flex flex-col">
                                <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                                  {stepIdx + 1}. {step.name}
                                </span>
                                <span className="text-sm text-gray-500">
                                  {step.description}
                                </span>
                              </span>
                            </a>
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div>
                      <label
                        htmlFor="accessToken"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Access Token
                      </label>
                      <div className="bg-white mt-1 relative rounded-md shadow-sm border-2 flex items-stretch flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <KeyIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="key"
                          name="accessToken"
                          id="accessToken"
                          value={
                            loadingToken ? "Loading token..." : accessToken
                          }
                          disabled={true}
                          className="p-2 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300 cursor-copy"
                        />
                        <button
                          type="button"
                          disabled={loadingToken}
                          onClick={copyTokenToClipboard}
                          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-md bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-50"
                        >
                          <DuplicateIcon
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6 ">
                    <p className="text-xl font-bold">Notes</p>
                    <ul className="list-disc text-sm text-gray-500 ml-4 my-2">
                      <li>
                        The Chrome extension works on all Chromium based
                        browsers (like Brave or Opera).
                      </li>
                      <li>
                        Support for other browsers is coming soon! If you want
                        your browser to be added, get in touch and let us know!
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Snackbar
            open={successTokenCopied}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Token copied to clipboard!
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              An error occurred - please try again or contact support
            </Alert>
          </Snackbar>
        </main>
      </div>
    </>
  );
}
