import { CheckIcon, ExclamationIcon, HomeIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TwitterIcon from "../../../assets/img/twitter-icon.png";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import "url-search-params-polyfill";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { Switch } from "@headlessui/react";

export default function TwitterSettings() {
  const auth = useAuth();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [removeIntegrationSuccess, setRemoveIntegrationSuccess] =
    useState<boolean>(false);
  const [syncBookmarksSuccess, setSyncBookmarksSuccess] =
    useState<boolean>(false);
  const [openRemoveIntegrationModal, setOpenRemoveIntegrationModal] =
    useState<boolean>(false);
  const [loginWithTwitterStepStatus, setLoginWithTwitterStepStatus] =
    useState(false);
  const [youreDoneStepStatus, setYoureDoneStepStatus] = useState(false);
  const navigate = useNavigate();
  const [twitterAuthenticated, setTwitterAuthenticated] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPreferences, setLoadingPreferences] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [
    syncTwitterBookmarksAsThreadsPreference,
    setSyncTwitterBookmarksAsThreadsPreference,
  ] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);

  const loginWithTwitter = async () => {
    try {
      const response = await api.getTwitterAuthorizeUrl();

      // Store temporary state and code verifier in sessionStorage
      sessionStorage.setItem(
        "twitterOauthSessionState",
        response.data.sessionState
      );
      sessionStorage.setItem(
        "twitterOauthCodeVerifier",
        response.data.codeVerifier
      );

      // Go to authorize url
      if (response.data.authorizeUrl) {
        window.location.href = response.data.authorizeUrl;
      } else {
        const message =
          "Error while trying to log in with twitter - No authorize URL was returned";
        console.error(message);
        setErrorMessage(message);
        setError(true);
      }
    } catch (error) {
      const message = "An error occurred - please try again or contact support";
      console.error(message, error);
      setErrorMessage(message);
      setError(true);
    }
  };

  const retrieveAccessToken = async () => {
    // Workflow for retrieving access token from Twitter and storing it in db
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    const sessionState = sessionStorage.getItem("twitterOauthSessionState");
    const codeVerifier = sessionStorage.getItem("twitterOauthCodeVerifier");

    if (code && state && sessionState && codeVerifier) {
      try {
        const authRes = await api.getAndStoreTwitterAccessToken(
          state,
          code,
          codeVerifier,
          sessionState
        );
        if (authRes.status === 200) {
          setSuccess(true);
        }
      } catch (error: any) {
        if ((error.message as string).includes("409")) {
          const message =
            "Error - this Twitter account has already been connected to another account";
          console.error(message, error.message);
          setErrorMessage(message);
        } else {
          const message = "";
          console.error("Error getting Twitter access token - ", error.message);
          setErrorMessage(message);
        }
        setError(true);
      }

      navigate("/connect/twitter");
    }

    // Workflow for checking if Twitter authenticated
    try {
      const twitterAuthStatus = await api.getUserTwitterAuthenticationStatus();
      setTwitterAuthenticated(twitterAuthStatus.data.authenticated);

      if (twitterAuthStatus.data.authenticated) {
        setLoginWithTwitterStepStatus(true);
        setYoureDoneStepStatus(true);
      }
      setLoading(false);
    } catch (error) {
      setError(true);
      console.error("Error checking if Twitter authenticated - ", error);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      retrieveAccessToken();
      api
        .getUserPreferences()
        .then((res) => {
          if (
            Object.prototype.hasOwnProperty.call(
              res.data,
              "syncTwitterBookmarksAsThreads"
            )
          ) {
            setSyncTwitterBookmarksAsThreadsPreference(
              res.data.syncTwitterBookmarksAsThreads
            );
          }
        })
        .catch((error) => {
          console.error("Error retrieving user's preferences - ", error);
        })
        .finally(() => setLoadingPreferences(false));
    }
  }, [auth.loading]);

  const removeIntegration = () => {
    api
      .removeUserTwitterIntegration()
      .then(() => {
        setRemoveIntegrationSuccess(true);
        window.location.reload();
      })
      .catch((error) => {
        setError(true);
        console.error("Error removing integration - ", error);
      });
  };

  const syncTwitterBookmarks = async () => {
    setLoading(true);
    try {
      await api.syncTwitterBookmarks();
      setSyncBookmarksSuccess(true);
    } catch (error) {
      setError(true);
      console.error("Error syncing Twitter bookmarks - ", error);
    }
    setLoading(false);
  };

  const updateSyncTwitterBookmarksAsThreadsPreference = async (
    newPreference: boolean
  ) => {
    setLoadingPreferences(true);

    try {
      await api.updateUserPreferences({
        syncTwitterBookmarksAsThreads: newPreference,
      });
      setSyncTwitterBookmarksAsThreadsPreference(newPreference);
    } catch (error) {
      setError(true);
      console.error("Error syncing Twitter bookmarks - ", error);
    }
    setLoadingPreferences(false);
  };

  const closeSnackbar = () => {
    setSuccess(false);
    setErrorMessage("");
    setError(false);
    setRemoveIntegrationSuccess(false);
  };

  const toggleLoginWithTwitterStepStatus = () => {
    setLoginWithTwitterStepStatus(!loginWithTwitterStepStatus);
  };

  const toggleYoureDoneStepStatus = () => {
    setYoureDoneStepStatus(!youreDoneStepStatus);
  };

  const steps = [
    {
      name: "Login with Twitter",
      description:
        "Authenticate Twitter for Tressel using the login button below",
      onClick: toggleLoginWithTwitterStepStatus,
      status: loginWithTwitterStepStatus ? "complete" : "current",
    },
    {
      name: "You're done!",
      description:
        "Your tweets, conversations, threads and bookmarks (if premium) will be automatically imported to Tressel periodically",
      onClick: toggleYoureDoneStepStatus,
      status: youreDoneStepStatus ? "complete" : "upcoming",
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <NavBar />

        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={TwitterIcon}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Twitter</h1>
                <p className="text-sm font-medium text-gray-500">
                  Import your saved tweets, threads and conversations and (for
                  premium users) bookmarks
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="notion-information-title">
                <div className="bg-yellow-100 shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notion-information-title"
                      className="text-gray-900 inline-flex"
                    >
                      <ExclamationIcon className="h-6 w-6" />
                      <span className="text-lg leading-6 font-bold">
                        Warning
                      </span>
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      If premium, your 50 most recent Twitter bookmarks will be
                      synced into Tressel (and to any connected apps). If you
                      don't wish these to be synced, remove them from your
                      bookmarks before authenticating Twitter
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="notion-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notion-information-title"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Instructions
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      How to get started importing from Twitter
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <nav aria-label="Progress">
                      <ol role="list" className="overflow-hidden">
                        {steps.map((step, stepIdx) => (
                          <li
                            key={step.name}
                            className={classNames(
                              stepIdx !== steps.length - 1 ? "pb-10" : "",
                              "relative"
                            )}
                          >
                            {step.status === "complete" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="cursor-pointer relative flex items-start group"
                                >
                                  <span className="h-9 flex items-center">
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                      <CheckIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : step.status === "current" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                  aria-current="step"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                                      <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            )}
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {loading ? (
                      <p>Checking if you're already authenticated...</p>
                    ) : (
                      <button
                        type="button"
                        onClick={loginWithTwitter}
                        style={{
                          backgroundColor: "#1DA1F2",
                        }}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <svg
                          className="w-5 h-5 mr-2"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                        {twitterAuthenticated
                          ? "Reauthenticate Twitter"
                          : "Login with Twitter"}
                      </button>
                    )}
                  </div>
                </div>
              </section>
              {twitterAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Sync all bookmarks
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This syncs your 800 most recent Twitter bookmarks
                            (the max allowed by Twitter). It will skip
                            duplicate/trashed bookmarks.
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => syncTwitterBookmarks()}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                      >
                        Sync
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {twitterAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <Switch.Group
                      as="div"
                      className="flex items-center justify-between"
                    >
                      <span className="flex flex-grow flex-col">
                        <Switch.Label
                          as="span"
                          className="text-sm font-medium text-gray-900"
                          passive
                        >
                          Sync bookmarks as threads
                        </Switch.Label>
                        <Switch.Description
                          as="span"
                          className="text-sm text-gray-500"
                        >
                          Save bookmarked tweets as threads. Leave this off if
                          you want bookmarks to be saved as individual tweets.
                        </Switch.Description>
                      </span>
                      <Switch
                        checked={syncTwitterBookmarksAsThreadsPreference}
                        disabled={loadingPreferences}
                        onChange={(e: boolean) =>
                          updateSyncTwitterBookmarksAsThreadsPreference(e)
                        }
                        className={classNames(
                          syncTwitterBookmarksAsThreadsPreference
                            ? "bg-indigo-600"
                            : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            syncTwitterBookmarksAsThreadsPreference
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </Switch.Group>
                  </div>
                </div>
              )}
              {twitterAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Remove integration
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This removes Tressel access (but not any existing
                            data from the integration)
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => setOpenRemoveIntegrationModal(true)}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        Remove Integration
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={success}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Twitter successfully authenticated!
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {errorMessage ??
                "An error occurred - please try again or contact support"}
            </Alert>
          </Snackbar>
          <Snackbar
            open={removeIntegrationSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Integration successfully removed!
            </Alert>
          </Snackbar>
          <Snackbar
            open={syncBookmarksSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Started syncing bookmarks! Syncing will continue in the background
            </Alert>
          </Snackbar>
        </main>
      </div>
      <DeleteModal
        action={() => removeIntegration()}
        open={openRemoveIntegrationModal}
        setOpen={setOpenRemoveIntegrationModal}
        title={"Are you sure you want to remove this integration?"}
        description={
          "Your existing data from the integration won't be removed but you'll have to set it up again after removal."
        }
      />
    </>
  );
}
