import { HTMLAttributeAnchorTarget, ReactNode } from "react";

type Props = {
  children?: ReactNode;
  href?: string;
  target: HTMLAttributeAnchorTarget;
  onClick?: () => void;
};
export function Button({ children, href, target, onClick }: Props) {
  const className =
    "bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mr-2";

  if (href) {
    return (
      <a href={href} target={target} className={className}>
        {children}
      </a>
    );
  }

  return (
    <button onClick={onClick} className={className}>
      {children}
    </button>
  );
}
