import { CheckIcon, HomeIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EvernoteIcon from "../../../assets/img/evernote-icon.png";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import "url-search-params-polyfill";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";

export default function EvernoteSettings() {
  const auth = useAuth();
  const [success, setSuccess] = useState<boolean>(false);
  const [removeIntegrationSuccess, setRemoveIntegrationSuccess] =
    useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loginWithEvernoteStepStatus, setLoginWithEvernoteStepStatus] =
    useState(false);
  const [youreDoneStepStatus, setYoureDoneStepStatus] = useState(false);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const [evernoteAccessToken, setEvernoteAccessToken] = useState<string>("");
  const [loadingToken, setLoadingToken] = useState<boolean>(true);
  const [loadingClearSync, setLoadingClearSync] = useState<boolean>(false);
  const [openRemoveIntegrationModal, setOpenRemoveIntegrationModal] =
    useState<boolean>(false);

  const loginWithEvernote = async () => {
    try {
      const requestToken: any = await api.getEvernoteRequestToken();

      // Set tokens and secrets in sessionStorage
      sessionStorage.setItem(
        "evernoteOauthToken",
        requestToken.data.oauthToken
      );
      sessionStorage.setItem(
        "evernoteOauthSecret",
        requestToken.data.oauthSecret
      );

      // Go to authorize url
      if (requestToken.data.authorizeUrl) {
        window.location.href = requestToken.data.authorizeUrl;
      }
    } catch (error) {
      console.error("Error while trying to log in with evernote -", error);
      setError(true);
    }
  };

  const clearSyncMemory = async () => {
    setLoadingClearSync(true);
    try {
      await api.clearEvernoteSyncMemory();
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
    setLoadingClearSync(false);
  };

  const closeSnackbar = () => {
    setSuccess(false);
    setRemoveIntegrationSuccess(false);
    setError(false);
  };

  const retrieveAccessToken = async () => {
    // Workflow for retrieving access token from Evernote and storing it in db
    const oauthVerifier = queryParams.get("oauth_verifier");
    const oauthToken = queryParams.get("oauth_token");
    const oauthSecret = sessionStorage.getItem("evernoteOauthSecret");

    if (oauthVerifier && oauthToken && oauthSecret) {
      // Get access token
      const accessTokenRes = await api.getEvernoteAccessToken(
        oauthToken,
        oauthSecret,
        oauthVerifier
      );
      if (accessTokenRes.status === 200) {
        // Store access token in database
        await api.setEvernoteAccessTokenInDb(accessTokenRes.data.token);
      } else {
        setError(true);
        console.error(
          "Error getting Evernote access token -",
          accessTokenRes.data
        );
      }
      navigate("/connect/evernote");
    }

    // Workflow for retrieving Evernote access token already stored in our db
    try {
      const evernoteAccessToken = await api.getEvernoteAccessTokenFromDb();
      setEvernoteAccessToken(evernoteAccessToken.data.token as string);
      setLoadingToken(false);
      if (evernoteAccessToken.data.token) {
        setLoginWithEvernoteStepStatus(true);
        setYoureDoneStepStatus(true);
      }
    } catch (error) {
      console.error("Error retrieving evernote access token from db - ", error);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      retrieveAccessToken();
    }
  }, [auth.loading]);

  const toggleLoginWithEvernoteStepStatus = () => {
    setLoginWithEvernoteStepStatus(!loginWithEvernoteStepStatus);
  };

  const toggleYoureDoneStepStatus = () => {
    setYoureDoneStepStatus(!youreDoneStepStatus);
  };

  const removeIntegration = () => {
    api
      .removeUserEvernoteIntegration()
      .then(() => {
        setRemoveIntegrationSuccess(true);
        window.location.reload();
      })
      .catch((error) => {
        setError(true);
        console.error("Error removing integration - ", error);
      });
  };

  const steps = [
    {
      name: "Login with Evernote",
      description:
        "Authenticate Evernote for Tressel using the login button below",
      onClick: toggleLoginWithEvernoteStepStatus,
      status: loginWithEvernoteStepStatus ? "complete" : "current",
    },
    {
      name: "You're done!",
      description:
        "Your saved content will be automatically exported to Evernote periodically",
      onClick: toggleYoureDoneStepStatus,
      status: youreDoneStepStatus ? "complete" : "upcoming",
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <NavBar />

        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={EvernoteIcon}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Evernote</h1>
                <p className="text-sm font-medium text-gray-500">
                  Sync to a notebook in Evernote
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                onClick={clearSyncMemory}
                disabled={loadingClearSync || loadingToken}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-50"
              >
                ✨ Clear Sync Memory
              </button>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="notion-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notion-information-title"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Instructions
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      How to get started importing from Evernote
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <nav aria-label="Progress">
                      <ol role="list" className="overflow-hidden">
                        {steps.map((step, stepIdx) => (
                          <li
                            key={step.name}
                            className={classNames(
                              stepIdx !== steps.length - 1 ? "pb-10" : "",
                              "relative"
                            )}
                          >
                            {step.status === "complete" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="cursor-pointer relative flex items-start group"
                                >
                                  <span className="h-9 flex items-center">
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                      <CheckIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : step.status === "current" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                  aria-current="step"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                                      <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            )}
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {loadingToken ? (
                      <p>Checking if you're already authenticated...</p>
                    ) : (
                      <button
                        type="button"
                        onClick={loginWithEvernote}
                        className="inline-flex bg-black items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <img className="w-5 h-5 mr-2" src={EvernoteIcon} />
                        {evernoteAccessToken
                          ? "Reauthenticate Evernote"
                          : "Login with Evernote"}
                      </button>
                    )}
                  </div>
                  {evernoteAccessToken && (
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      {evernoteAccessToken !== "expired" ? (
                        <div>
                          <p>
                            You're already authenticated, but you may need to
                            reauthenticate if your access token expires
                          </p>
                          <p>
                            Press the <strong>"Clear Sync Memory"</strong>{" "}
                            button to resync all your saved stuff into Evernote
                            from scratch
                          </p>
                        </div>
                      ) : (
                        <p>
                          Your evernote access token has expired. You'll need to
                          reauthenticate and renew access for your tweets to
                          continue syncing.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </section>
              {evernoteAccessToken && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Remove integration
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This removes Tressel access (but not any existing
                            data from the integration)
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loadingToken}
                        onClick={() => setOpenRemoveIntegrationModal(true)}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        Remove Integration
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={success}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Sync memory successfully cleared!
            </Alert>
          </Snackbar>
          <Snackbar
            open={removeIntegrationSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Successfully removed integration!
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              An error occurred - please try again or contact support
            </Alert>
          </Snackbar>
        </main>
      </div>
      <DeleteModal
        action={() => removeIntegration()}
        open={openRemoveIntegrationModal}
        setOpen={setOpenRemoveIntegrationModal}
        title={"Are you sure you want to remove this integration?"}
        description={
          "Your existing data from the integration won't be removed but you'll have to set it up again after removal."
        }
      />
    </>
  );
}
