import { CheckIcon, HomeIcon, UserIcon } from "@heroicons/react/solid";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InstapaperIcon from "../../../assets/img/instapaper-icon.png";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { NavBar } from "../../../components/NavBar/NavBar";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";

export default function InstapaperSettings() {
  const auth = useAuth();
  const [success, setSuccess] = useState<boolean>(false);
  const [removeIntegrationSuccess, setRemoveIntegrationSuccess] =
    useState<boolean>(false);
  const [syncHighlightsSuccess, setSyncHighlightsSuccess] =
    useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loginWithInstapaperStepStatus, setLoginWithInstapaperStepStatus] =
    useState(false);
  const [youreDoneStepStatus, setYoureDoneStepStatus] = useState(false);
  const [instapaperUsername, setInstapaperUsername] = useState<string>("");
  const [instapaperPassword, setInstapaperPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [instapaperAuthenticated, setInstapaperAuthenticated] =
    useState<boolean>(false);
  const [openRemoveIntegrationModal, setOpenRemoveIntegrationModal] =
    useState<boolean>(false);

  const authorizeInstapaper = async () => {
    setLoading(true);
    try {
      const response: any = await api.setInstapaperOauthCredentials(
        instapaperUsername,
        instapaperPassword
      );

      setInstapaperAuthenticated(true);
    } catch (error) {
      console.error("Error while trying to log in with instapaper -", error);
      setError(true);
    }
    setLoading(false);
  };

  const closeSnackbar = () => {
    setSuccess(false);
    setRemoveIntegrationSuccess(false);
    setError(false);
  };

  const checkUserInstapaperAuthenticationStatus = async () => {
    try {
      const instapaperAuthStatus =
        await api.getUserInstapaperAuthenticationStatus();
      setInstapaperAuthenticated(instapaperAuthStatus.data.authenticated);
    } catch (error) {
      console.error("Error checking if Instapaper authenticated - ", error);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      checkUserInstapaperAuthenticationStatus().finally(() =>
        setLoading(false)
      );
    }
  }, [auth.loading]);

  const toggleLoginWithInstapaperStepStatus = () => {
    setLoginWithInstapaperStepStatus(!loginWithInstapaperStepStatus);
  };

  const toggleYoureDoneStepStatus = () => {
    setYoureDoneStepStatus(!youreDoneStepStatus);
  };

  const removeIntegration = () => {
    api
      .removeUserInstapaperIntegration()
      .then(() => {
        setRemoveIntegrationSuccess(true);
        window.location.reload();
      })
      .catch((error) => {
        setError(true);
        console.error("Error removing integration - ", error);
      });
  };

  const syncInstapaperHighlights = async () => {
    setLoading(true);
    try {
      await api.syncInstapaperHighlights();
      setSyncHighlightsSuccess(true);
    } catch (error) {
      setError(true);
      console.error("Error syncing Instapaper highlights - ", error);
    }
    setLoading(false);
  };

  const steps = [
    {
      name: "Login with Instapaper",
      description: "Authenticate Instapaper for Tressel using the button below",
      onClick: toggleLoginWithInstapaperStepStatus,
      status: loginWithInstapaperStepStatus ? "complete" : "current",
    },
    {
      name: "You're done!",
      description:
        "Your highlights will be automatically imported into Tressel periodically",
      onClick: toggleYoureDoneStepStatus,
      status: youreDoneStepStatus ? "complete" : "upcoming",
    },
  ];

  const breadcrumbs = [
    { name: "Settings", href: "/settings", current: false },
    { name: "Integrations", href: "/settings/integrations", current: false },
    {
      name: "Instapaper",
      href: "/settings/integrations/import-instapaper",
      current: true,
    },
  ];

  return (
    <>
      <div className="min-h-full">
        <NavBar />
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="border-t border-gray-200 py-3">
              <nav className="flex" aria-label="Breadcrumb">
                <div className="hidden sm:block">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <Link to={"/dashboard"}>
                          <div className="text-gray-400 hover:text-gray-500">
                            <HomeIcon
                              className="flex-shrink-0 h-5 w-5"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Home</span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    {breadcrumbs.map((item) => (
                      <li key={item.name}>
                        <div className="flex items-center">
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-gray-300"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                          </svg>
                          <Link to={item.href}>
                            <div
                              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </div>
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={InstapaperIcon}
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Instapaper</h1>
                <p className="text-sm font-medium text-gray-500">
                  Sync your Instapaper highlights to Tressel
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="notion-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notion-information-title"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      Instructions
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      How to get started importing from Instapaper
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <nav aria-label="Progress">
                      <ol role="list" className="overflow-hidden">
                        {steps.map((step, stepIdx) => (
                          <li
                            key={step.name}
                            className={classNames(
                              stepIdx !== steps.length - 1 ? "pb-10" : "",
                              "relative"
                            )}
                          >
                            {step.status === "complete" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="cursor-pointer relative flex items-start group"
                                >
                                  <span className="h-9 flex items-center">
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                      <CheckIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : step.status === "current" ? (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                  aria-current="step"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                                      <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : (
                              <>
                                {stepIdx !== steps.length - 1 ? (
                                  <div
                                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <a
                                  onClick={step.onClick}
                                  className="relative flex items-start group"
                                >
                                  <span
                                    className="h-9 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                    </span>
                                  </span>
                                  <span className="ml-4 min-w-0 flex flex-col">
                                    <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                      {step.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {step.description}
                                    </span>
                                  </span>
                                </a>
                              </>
                            )}
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {loading ? (
                      <p>Checking if you're already authenticated...</p>
                    ) : instapaperAuthenticated ? (
                      <p>
                        You're authenticated. If you wish to reauthenticate,
                        remove the Instapaper integration and authenticate
                        again.
                      </p>
                    ) : (
                      <>
                        <div className="my-2">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email or username
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              name="username"
                              id="username"
                              disabled={loading}
                              onChange={(e) =>
                                setInstapaperUsername(e.target.value)
                              }
                              value={instapaperUsername}
                              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Username"
                            />
                          </div>
                        </div>
                        <div className="my-2">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              disabled={loading}
                              onChange={(e) =>
                                setInstapaperPassword(e.target.value)
                              }
                              value={instapaperPassword}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Password, if you have one"
                            />
                          </div>
                        </div>
                        <p className="text-sm text-gray-500 my-1">
                          Your credentials are not stored or accessed by Tressel
                          after initially authenticating Instapaper.
                        </p>
                        <button
                          type="button"
                          onClick={authorizeInstapaper}
                          className="inline-flex bg-black items-center my-2 px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Authenticate
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </section>
              {instapaperAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Sync more highlights
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This syncs your 500 most recent Instapaper
                            highlights. It will skip duplicate/trashed
                            highlights.
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => syncInstapaperHighlights()}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60"
                      >
                        Sync
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {instapaperAuthenticated && (
                <div className="fadeIn">
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Remove integration
                        </span>
                        <span className="text-sm text-gray-500">
                          <p>
                            This removes Tressel access (but not any existing
                            data from the integration)
                          </p>
                        </span>
                      </span>
                      <button
                        disabled={loading}
                        onClick={() => setOpenRemoveIntegrationModal(true)}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-60"
                      >
                        Remove Integration
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={success}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Sync memory successfully cleared!
            </Alert>
          </Snackbar>
          <Snackbar
            open={removeIntegrationSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Successfully removed integration!
            </Alert>
          </Snackbar>
          <Snackbar
            open={syncHighlightsSuccess}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Started syncing highlights! Syncing will continue in the
              background
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              An error occurred - please try again or contact support
            </Alert>
          </Snackbar>
        </main>
      </div>
      <DeleteModal
        action={() => removeIntegration()}
        open={openRemoveIntegrationModal}
        setOpen={setOpenRemoveIntegrationModal}
        title={"Are you sure you want to remove this integration?"}
        description={
          "Your existing data from the integration won't be removed but you'll have to set it up again after removal."
        }
      />
    </>
  );
}
