import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { MasterDetail } from "theme";
import { AppSidebar } from "components/AppSidebar";

export default function SubscriptionSettings() {
  const auth = useAuth();
  const [subscription, setSubscription] = useState<any>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const goToManageSubscriptionPortal = () => {
    setLoading(true);
    api
      .getStripePortalSessionUrl()
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const goToChooseSubscriptionPage = () => {
    navigate("/choose-subscription");
  };

  const getCurrentSubscription = async () => {
    api
      .getCustomerSubscriptions()
      .then((subscriptions) => {
        if (subscriptions.data.subscriptions.length > 0) {
          for (const subscription of subscriptions.data.subscriptions) {
            if (
              subscription.status === "active" ||
              subscription.status === "trialing"
            ) {
              setSubscription(subscription);
              return;
            }
          }
          setSubscription(undefined);
        } else {
          setSubscription(undefined);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      getCurrentSubscription();
    }
  }, [auth.loading]);

  return (
    <MasterDetail sidebar={<AppSidebar />}>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        {/* Plan */}
        <section aria-labelledby="plan-heading">
          <form action="#" method="POST">
            <div className="shadow sm:rounded-md sm:overflow-hidden mx-2 sm:mx-0">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h2
                    id="plan-heading"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Subscription
                  </h2>
                </div>
                <div>
                  {loading ? (
                    <div className="my-3 animate-pulse space-y-1">
                      <div className="bg-gray-100 text-gray-100 rounded">
                        You're currently on the <strong>$5</strong> plan
                      </div>
                      <div className="bg-gray-100 text-gray-100 rounded">
                        <div>
                          Your subscription will be cancelled on{" "}
                          {new Date().toDateString()}
                        </div>
                      </div>
                    </div>
                  ) : subscription ? (
                    <div className="fadeIn">
                      <div>
                        You're currently on the{" "}
                        <strong>
                          ${subscription.plan.amount / 100}/
                          {subscription.plan.interval}
                        </strong>{" "}
                        plan
                      </div>
                      <div>
                        {subscription.cancel_at ? (
                          <div>
                            Your subscription will be cancelled on{" "}
                            {new Date(
                              subscription.cancel_at * 1000
                            ).toDateString()}
                          </div>
                        ) : (
                          <div>
                            Your subscription will be renewed on{" "}
                            {new Date(
                              subscription.current_period_end * 1000
                            ).toDateString()}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="fadeIn">
                      You're currently <strong>not subscribed </strong>to
                      Tressel
                    </div>
                  )}
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-left sm:px-6">
                {subscription && (
                  <button
                    disabled={loading}
                    onClick={goToManageSubscriptionPortal}
                    className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mr-2"
                  >
                    Manage Subscription
                  </button>
                )}
                <button
                  disabled={loading}
                  onClick={goToChooseSubscriptionPage}
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  View Plans
                </button>
              </div>
            </div>
          </form>
        </section>
        {!loading && !subscription && (
          <section className="fadeIn">
            <div className="shadow sm:rounded-md sm:overflow-hidden mx-2 sm:mx-0">
              <div className="bg-black text-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h2
                    id="plan-heading"
                    className="text-lg leading-6 font-medium text-white"
                  >
                    ⚡ Upgrade
                  </h2>
                </div>
                <div>
                  <>
                    <div className="mb-2">
                      <Link to={"/choose-subscription"}>
                        <span className="text-indigo-500 hover:text-indigo-600">
                          Upgrade to a Tressel premium plan
                        </span>
                      </Link>{" "}
                      to:
                    </div>
                    <div className="text-sm font-base space-y-1">
                      <div>
                        ✅ Privately save{" "}
                        <strong>Twitter and Reddit content</strong> via DMs and
                        bookmarks
                      </div>
                      <div>
                        ✅ Import <strong>Kindle highlights</strong>{" "}
                        automatically via Chrome extension
                      </div>
                      <div>
                        ✅ Import <strong>Pocket highlights</strong>{" "}
                        automatically
                      </div>
                      <div>
                        ✅ Import <strong>Instapaper highlights</strong>{" "}
                        automatically
                      </div>
                      <div>
                        ✅ Import <strong>Raindrop highlights</strong>{" "}
                        automatically
                      </div>
                      <div>
                        ✅ Import <strong>Hypothes.is annotations</strong>{" "}
                        automatically
                      </div>
                      <div>
                        ✅ Import <strong>HackerNews favourites</strong>{" "}
                        automatically
                      </div>
                      <div>
                        ✅ <strong>Quick capture highlights</strong> in seconds
                        via Chrome extension
                      </div>
                      <div>
                        ✅ <strong>Daily and weekly highlight emails</strong> to
                        resurface content you've saved
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-900 text-left sm:px-6">
                {
                  <button
                    disabled={loading}
                    onClick={goToChooseSubscriptionPage}
                    className="bg-indigo-700 border border-transparent rounded-md shadow-lg py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800"
                  >
                    ⚡ Upgrade
                  </button>
                }
              </div>
            </div>
          </section>
        )}
      </div>
    </MasterDetail>
  );
}
